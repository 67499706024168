import styled from "styled-components";
import { ButtonText } from "@diana-ui/typography";

export const StyledButton = styled.button`
  color: blue;
  cursor: pointer;
  outline-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButtonText = styled(ButtonText)``;

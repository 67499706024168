import styled, { css } from "styled-components";
import { BaseButton } from "@diana-ui/button";

export default styled(BaseButton).attrs(props => ({
  disabled: !!props.disabled
}))(
  ({ theme, disabled }) => css`
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 50px;
    color: ${theme.colors.black};
    padding: ${theme.spaceUnit.sm} ${theme.spaceUnit.xxl};
    background-color: transparent;

    :hover {
      border-color: ${theme.colors.grey.grey25};
      background-color: ${theme.colors.grey.grey25};
    }

    :active {
      background-color: ${theme.colors.grey.grey50};
      border-color: ${theme.colors.grey.grey50};
    }

    ${disabled &&
    css`
      color: ${theme.colors.grey.grey50};
      cursor: default;

      :hover {
        background-color: transparent;
        border-color: transparent;
      }
    `}
  `
);

import React, { SVGAttributes, useMemo } from "react";
import { DefaultTheme, useTheme } from "styled-components";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type IconNames = keyof DefaultTheme["icons"] & string;

export interface IProps {
  name: IconNames;
  src?: string;
  size?: number;
}

export type IIconProps = IProps & SVGAttributes<Element>;

const camelToKebab = (input: string) => {
  return input.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
};

const Icon: React.FC<IIconProps> = ({
  name,
  height,
  width,
  stroke,
  fill,
  color,
  src,
  size,
  cx,
  ...props
}) => {
  const theme = useTheme();

  const themeIcons = theme.icons;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SvgIcon: any = useMemo(() => {
    const match = Object.keys(themeIcons).find(
      key => key.toLowerCase() === name.toLowerCase() || camelToKebab(key) === name.toLowerCase()
    );

    return src || (match && themeIcons[match]) || "";
  }, [name, src, themeIcons]);

  // Building props this way to avoid override svg previous values, only if specified.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const newProps: any = {};
  if (size || width) {
    newProps.width = size || width;
  }
  if (size || height) {
    newProps.height = size || height;
  }
  if (fill || color) {
    newProps.fill = fill || color;
  }
  if (stroke) {
    newProps.stroke = stroke;
  }

  return <SvgIcon {...props} {...newProps} />;
};

Icon.displayName = "Icon";

export default Icon;

import styled, { css } from "styled-components";
import PrimaryButton from "./PrimaryButton";

export default styled(PrimaryButton)(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.white};
    color: ${theme.colors.white};

    :hover {
      background-color: #2e2e2e;
    }

    :active {
      background-color: ${theme.colors.white};
      color: ${theme.colors.black};
    }
  `
);

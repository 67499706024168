import { keyframes } from "styled-components";

export const expandHorizontally = keyframes`
  from {
    opacity: 0;
    transform: scale(0, 1);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
`;

export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const floating = keyframes`
  from {
    transform: translate(0px, 0px) scale(1);
  }
  to {
    transform: translate(50px, 50px) scale(1.65);
  }
`;

export const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const expand = keyframes`
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
`;

export const slideFromRight = keyframes`
  0% {
    transform: scale(0, 1);
  }
  100% {
    transform: scale(1, 1);
  }
`;

export const fadeInFromBelow = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ripple = keyframes`
  0% {
    opacity: 0;
    transform: scale(0);
  }
  60% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

export const pulse = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 var(--color-grey-grey25);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 4px rgba(108, 210, 139, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(108, 210, 139, 0);
  }
`;

export const shine = keyframes`
  to {
    background-position: 120% 0, /* move highlight to right */ 0 0;
  }
`;

export const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

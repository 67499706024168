import { Icon } from "@ddm-design-system/icon";
import { Description } from "@ddm-design-system/typography";
import styled, { css } from "styled-components";

export const Wrapper = styled.div(
  () => css`
    display: flex;
    align-items: center;
    flex-direction: column;
  `
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const commonBarStyles = (theme: any) => css`
  border-radius: 10px;
  height: 8px;
  margin-bottom: ${theme.spaceUnit.md};
`;

export const InnerBar = styled.div(
  ({ theme }) => css`
    ${commonBarStyles(theme)}
    background-color: ${theme.colors.black};
    transition: width 1.5s;
  `
);

export const OuterBar = styled.div(
  ({ theme }) => css`
    ${commonBarStyles(theme)}
    background-color: ${theme.colors.grey.greenish};
    position: relative;
  `
);

export const DividerWrapper = styled.div(
  () => css`
    position: absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    top: -4px;
  `
);

export const StyledDivider = styled.div(
  ({ theme }) => css`
    background-color: ${theme.colors.grey.grey50};
    height: 24px;
    margin-bottom: ${theme.spaceUnit.xxs};
    width: 1px;
  `
);

export const LeftDividerIcon = styled(Icon)(
  ({ theme }) => css`
    fill: ${theme.colors.grey.grey50};
  `
);

export const StyledLabel = styled(Description)``;

export const StyledIcon = styled(Icon)``;

import React, { useState, useEffect } from "react";
import * as ProgressBarStyles from "./ProgressBar.style";

const DEFAULT_WIDTH_PX = 270;

export interface IProps {
  label?: string;
  leftDividerValue?: number;
  rightDividerValue?: number;
  showDividerIcons?: boolean;
  showDividers?: boolean;
  value: number;
  width?: number;
}
export type IProgressBarProps = IProps & JSX.IntrinsicElements["div"];

const ProgressBar: React.FC<IProgressBarProps> = ({
  className,
  label,
  leftDividerValue = 10,
  rightDividerValue = 90,
  showDividerIcons = false,
  showDividers = false,
  value,
  width = DEFAULT_WIDTH_PX
}) => {
  const [widthTimeout, setWidthTimeout] = useState(false);

  // delay setting the inner bar width for the css transition to take effect
  useEffect(() => {
    setTimeout(() => {
      setWidthTimeout(true);
    }, 0);
  }, []);

  const finalValue = value > 100 ? 100 : value;

  return (
    <ProgressBarStyles.Wrapper className={className}>
      <ProgressBarStyles.OuterBar style={{ width }}>
        <ProgressBarStyles.InnerBar
          style={{ width: widthTimeout ? `${finalValue}%` : 0 }}
        />
        {showDividers && (
          <ProgressBarStyles.DividerWrapper
            style={{
              left: showDividerIcons
                ? `calc(${leftDividerValue}% - 7px)`
                : `${leftDividerValue}%`
            }}
          >
            <ProgressBarStyles.StyledDivider />
            {showDividerIcons && (
              <ProgressBarStyles.LeftDividerIcon name="Ok" />
            )}
          </ProgressBarStyles.DividerWrapper>
        )}
        {showDividers && (
          <ProgressBarStyles.DividerWrapper
            style={{
              right: showDividerIcons
                ? `calc(${100 - rightDividerValue}% - 7px)`
                : `${100 - rightDividerValue}%`
            }}
          >
            <ProgressBarStyles.StyledDivider />
            {showDividerIcons && <ProgressBarStyles.StyledIcon name="Ok" />}
          </ProgressBarStyles.DividerWrapper>
        )}
      </ProgressBarStyles.OuterBar>
      {label && (
        <ProgressBarStyles.StyledLabel>{label}</ProgressBarStyles.StyledLabel>
      )}
    </ProgressBarStyles.Wrapper>
  );
};

ProgressBar.displayName = "ProgressBar";

export { ProgressBarStyles };

export default ProgressBar;

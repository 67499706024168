import React from "react";
import styled, { css } from "styled-components";
import {
  BaseButton,
  IBaseButtonProps,
  BaseButtonStyles
} from "@diana-ui/button";
import { Icon, IconNames } from "@ddm-design-system/icon";

const ICON_SIZE_PX = 16;

export interface IProps {
  icon: IconNames;
  iconColor?: string;
  danger?: boolean;
}

export const StyledIcon = styled(Icon)``;

export type ITertiaryButtonProps = IProps & IBaseButtonProps;

export default styled(BaseButton).attrs<ITertiaryButtonProps>(
  ({ icon, disabled, danger, iconColor, children }) => ({
    disabled: !!disabled,
    isIconButton: !children,
    renderLeftIcon: () => (
      <StyledIcon
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        name={icon}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        fill={(!disabled && !danger && iconColor) || undefined}
      />
    )
  })
)<ITertiaryButtonProps & { isIconButton?: boolean }>(
  ({ theme, danger, isIconButton, disabled }) => css`
    border: unset;
    color: ${!danger ? theme.colors.black : theme.colors.alert.alert100};
    padding: ${isIconButton ? "12px" : theme.spaceUnit.xs};
    background-color: transparent;
    fill: ${theme.colors.grey.grey100};

    ${BaseButtonStyles.StyledButtonText} {
      margin-left: ${isIconButton ? 0 : theme.spaceUnit.xs};
    }

    ${StyledIcon} {
      height: ${isIconButton
        ? ICON_SIZE_PX
        : theme.typography.buttonText.fontSize};
      width: ${isIconButton
        ? ICON_SIZE_PX
        : theme.typography.buttonText.fontSize};

      ${disabled &&
      css`
        fill: ${theme.colors.grey.grey50};
      `}

      ${danger &&
      css`
        fill: ${theme.colors.alert.alert50};
      `}
    }

    ${disabled &&
    css`
      color: ${theme.colors.grey.grey50};
      cursor: default;

      :hover {
        background-color: transparent;
        border: unset;
      }
    `}
  `
);

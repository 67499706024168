import styled, { css } from "styled-components";
import { Icon as BaseIcon } from "@diana-ui/icon";
import type { ITextInputProps } from "./TextInput";

export const Fieldset = styled.fieldset<ITextInputProps>(
  ({ theme, disabled, hasError }) => css`
    position: relative;
    height: 38px;
    display: flex;
    padding: 2px 8px;
    min-inline-size: auto;

    ${disabled &&
    css`
      background-color: ${theme.colors.grey.grey25};
    `}

    ${hasError &&
    css`
      border-color: ${theme.colors.alert.alert100};
    `}
  `
);

type ILegendProps = ITextInputProps & {
  isFocused: boolean;
  legendWidth: number;
  hasContent: boolean;
};

/**
 * Text's input legend component
 */
export const Legend = styled.legend<ILegendProps>(
  ({ theme, focusLabel, label, isFocused, hasContent, legendWidth }) => css`
    width: 0;
    pointer-events: none;
    padding: 0;
    text-align: left;
    opacity: 0;
    transition: width 0.15s;
    line-height: 11px;
    height: 0;

    ${(focusLabel || isFocused) &&
    label &&
    hasContent &&
    css`
      padding: 0 2px;
    `}

    ${(focusLabel || isFocused || hasContent) && {
      width: legendWidth,
      padding: "0 2px"
    }}

    @media screen and (max-width: ${theme.breakpoints.MOBILE_1}px) {
      height: 3px;
    }
  `
);

export const HiddenLabel = styled.span(
  ({ theme }) => css`
    opacity: 0;
    position: absolute;
    pointer-events: none;
    height: 0;
    ${theme.typography.label}
  `
);

export const LabelContainer = styled.div(
  ({ theme }) => css`
    position: absolute;
    top: 0;
    left: ${theme.spaceUnit.xxs};
    height: 40px;
    pointer-events: none;
    display: flex;
    align-items: center;
  `
);

type ITextLabelProps = ITextInputProps & {
  isFocused: boolean;
  hasContent: boolean;
  labelWithPrefix: boolean;
};

export const TextLabel = styled.div<ITextLabelProps>(
  ({ theme, isFocused, focusLabel, hasContent, labelWithPrefix }) => css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: ${theme.colors.grey.grey100};
    padding: 0 ${theme.spaceUnit.xxs};
    transition: transform 0.1s, font-size 0.1s, padding 0.1s;
    transition-timing-function: ease-in;
    pointer-events: none;
    ${theme.typography.body}

    ${(focusLabel || isFocused || hasContent) &&
    css`
      transform: translate(2px, -21px);
      padding-left: ${theme.spaceUnit.xxs};
      ${theme.typography.label}
    `}

    ${labelWithPrefix &&
    css`
      padding-left: ${theme.spaceUnit.xl};
    `}
  `
);

export const InputContainer = styled.div(
  () => css`
    display: flex;
    align-items: center;
    flex: 1;
  `
);

export const Input = styled.input<ITextInputProps>(
  ({ theme, disabled }) => css`
    outline: none;
    border: none;
    width: 100%;
    height: 35px;
    flex: 1;
    ${theme.typography.body};

    ${disabled &&
    css`
      background-color: ${theme.colors.grey.grey25};
    `}
  `
);

export const PrefixWrapper = styled.div(({ theme }) => ({ marginRight: theme.spaceUnit.xs }));

export const SuffixWrapper = styled.div(({ theme }) => ({ marginLeft: theme.spaceUnit.xs }));

const Icon = BaseIcon;

export const PrefixIcon = styled(Icon)(
  ({ theme }) => css`
    margin-right: ${theme.spaceUnit.xs};
  `
);

export const SuffixIcon = styled(Icon)(
  ({ theme }) => css`
    margin-left: ${theme.spaceUnit.xs};
  `
);

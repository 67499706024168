import React from "react";
import styled, { css } from "styled-components";
import { Icon, IconNames } from "@ddm-design-system/icon";
import { BaseButton, IBaseButtonProps } from "@diana-ui/button";

const ICON_SIZE_PX = 8;

export const StyledIcon = styled(Icon)(
  ({ theme }) => css`
    height: ${ICON_SIZE_PX}px;
    width: ${ICON_SIZE_PX}px;
    stroke: ${theme.colors.black};
    margin-left: ${theme.spaceUnit.xxs};
  `
);

export type ILinkButtonProps = IBaseButtonProps & { icon?: IconNames };

export default styled(BaseButton).attrs<ILinkButtonProps>(props => ({
  renderRightIcon: () => {
    return (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      <StyledIcon
        name={props.icon || "ChevronRight"}
        fill={
          props.theme.mode === "dark" ? props.theme.colors.white : undefined
        }
        stroke={
          props.theme.mode === "dark" ? props.theme.colors.white : undefined
        }
      />
    );
  }
}))<ILinkButtonProps>(
  ({ theme }) => css`
    border: unset;
    color: ${theme.mode === "dark" ? theme.colors.white : theme.colors.black};
    padding: ${theme.spaceUnit.xs};
    background-color: transparent;
  `
);

import styled, { css } from "styled-components";
import TextInput from "./TextInput";

export const StyledBaseTextInput = styled(TextInput)``;

/**
 * Component wrapper
 */
export const ErrorTextInputWrapper = styled.div``;

export const HelperLabel = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    ${theme.typography.label};
  `
);

export const TypedLabel = styled.span<{ error?: boolean; hint?: boolean }>(
  ({ theme, error, hint }) => css`
    ${error &&
    css`
      color: ${theme.colors.alert.alert100};
    `}

    ${hint &&
    css`
      color: ${theme.colors.black};
    `}
  `
);

import styled, { css } from "styled-components";
import {
  ErrorTextInput as BaseErrorTextInput,
  ErrorTextInputStyles
} from "@diana-ui/textinput";
import { FontWeight } from "@ddm-design-system/tokens";
import TextInput, { baseTextInputStylesheet } from "./TextInput";

const ErrorTextInput = styled(BaseErrorTextInput).attrs({
  CustomTextInputComponent: TextInput
})(
  ({ theme, label, error, hint, disabled, prefixIcon }) => css`
    ${ErrorTextInputStyles.HelperLabel} {
      margin-top: ${theme.spaceUnit.xxs};
      font-weight: ${FontWeight.MEDIUM};
    }

    ${ErrorTextInputStyles.TypedLabel} {
      color: ${error
        ? theme.colors.alert.alert100
        : hint
        ? theme.colors.grey.grey100
        : ""};
    }

    ${ErrorTextInputStyles.StyledBaseTextInput} {
      ${baseTextInputStylesheet({
        theme,
        label,
        hasError: error,
        disabled,
        prefixIcon
      })}
    }
  `
);

export { ErrorTextInputStyles };

export type { IErrorTextInputProps } from "@diana-ui/textinput";

export default ErrorTextInput;

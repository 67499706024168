import React from "react";
import styled, { css } from "styled-components";
import { Icon, IconNames } from "@diana-ui/icon";

export interface ISplashProps {
  className?: string;
  infinite?: boolean;
  $infinite?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const animation = (theme: any) => css`
  animation-name: ${theme.animations.ripple};
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
`;

export const SplashIcon = styled(Icon)<ISplashProps>(
  ({ theme, $infinite }) => css`
    stroke: ${theme.colors.primary};
    stroke-opacity: 0.5;

    > path {
      opacity: 1;
      animation-name: ${theme.animations.ripple};
      animation-duration: 1.5s;
      animation-iteration-count: forwards;
      transform-origin: 45% 40%;
    }

    > .path1 {
      animation-delay: 0s;
      animation-duration: 1.5s;
      ${$infinite && animation(theme)};
    }

    > .path2 {
      animation-delay: 0.05s;
      animation-duration: 1.5s 0.05s;
      ${$infinite && animation(theme)};
    }

    > .path3 {
      animation-delay: 0.1s;
      animation-duration: 1.5s 0.1s;
      ${$infinite && animation(theme)};
    }

    > .path4 {
      animation-delay: 0.15s;
      animation-duration: 1.5s 0.15s;
      ${$infinite && animation(theme)};
    }

    > .path5 {
      animation-delay: 0.2s;
      animation-duration: 1.5s 0.2s;
      ${$infinite && animation(theme)};
    }

    > .path6 {
      animation-delay: 0.25s;
      animation-duration: 1.5s 0.25s;
      ${$infinite && animation(theme)};
    }

    > .path7 {
      animation-delay: 0.3s;
      animation-duration: 1.5s 0.3s;
      ${$infinite && animation(theme)};
    }

    > .path8 {
      animation-delay: 0.35s;
      animation-duration: 1.5s 0.35s;
      ${$infinite && animation(theme)};
    }

    > .path9 {
      animation-delay: 0.4s;
      animation-duration: 1.5s 0.4s;
      ${$infinite && animation(theme)};
    }

    > .path10 {
      animation-delay: 0.45s;
      animation-duration: 1.5s 0.45s;
      ${$infinite && animation(theme)};
    }
  `
);

export const Splash = styled.div(
  () => css`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `
);

const SplashLoading: React.FC<ISplashProps> = ({ className, infinite }) => (
  <Splash className={className}>
    <SplashIcon name={"DmLoading" as IconNames} $infinite={infinite} />
  </Splash>
);

export default SplashLoading;

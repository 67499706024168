import ddmtheme from "./theme.ddm";

export default {
  ...ddmtheme,
  mode: "dark",
  typography: {
    pageTitle: {
      ...ddmtheme.typography.pageTitle,
      color: "white"
    },
    subtitle: {
      ...ddmtheme.typography.subtitle,
      color: "white"
    },
    mainTitle: {
      ...ddmtheme.typography.mainTitle,
      color: "white"
    },
    sectionTitle: {
      ...ddmtheme.typography.sectionTitle,
      color: "white"
    },
    buttonText: {
      ...ddmtheme.typography.buttonText,
      color: "white"
    },
    bodyHighlight: {
      ...ddmtheme.typography.bodyHighlight,
      color: "white"
    },
    body: {
      ...ddmtheme.typography.body,
      color: ddmtheme.colors.grey.grey100
    },
    descriptionMedium: {
      ...ddmtheme.typography.descriptionMedium,
      color: "white"
    },
    description: {
      ...ddmtheme.typography.description,
      color: "white"
    },
    labelMedium: {
      ...ddmtheme.typography.labelMedium,
      color: "white"
    },
    label: {
      ...ddmtheme.typography.label,
      color: "white"
    },
    notificationsNumbers: {
      ...ddmtheme.typography.notificationsNumbers,
      color: "white"
    }
  }
};

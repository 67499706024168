import React, { ButtonHTMLAttributes } from "react";
import * as BaseButtonStyles from "./Button.style";

export interface IProps {
  /**
   * react element to be rendered on the left side
   */
  renderLeftIcon?: () => JSX.Element;
  /**
   * react element to be rendered on the right side
   */
  renderRightIcon?: () => JSX.Element;
}

export type IBaseButtonProps = IProps & ButtonHTMLAttributes<Element>;

const BaseButton: React.FC<IBaseButtonProps> = ({
  className,
  children,
  renderLeftIcon,
  renderRightIcon,
  ...props
}) => (
  <BaseButtonStyles.StyledButton className={className} type="submit" {...props}>
    {renderLeftIcon && renderLeftIcon()}
    {children && <BaseButtonStyles.StyledButtonText>{children}</BaseButtonStyles.StyledButtonText>}
    {renderRightIcon && renderRightIcon()}
  </BaseButtonStyles.StyledButton>
);

export { BaseButtonStyles };

export default BaseButton;

import { useEffect, useState } from "react";
import { ResizeObserver as Polyfill, ResizeObserverEntry } from "@juggle/resize-observer";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useResizeObserver(
  callback: (entries: ResizeObserverEntry[]) => void,
  dependencies: unknown[]
): any {
  const [observer, setObserver] = useState();

  useEffect(() => {
    if (!observer) {
      setObserver(
        new ((window as any).ResizeObserver || Polyfill)((entries: ResizeObserverEntry[]) => {
          // avoid ResizeObserver loop limit exceeded error
          window.requestAnimationFrame(() => {
            if (Array.isArray(entries) && entries.length) {
              callback(entries);
            }
          });
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callback, ...dependencies]);

  return observer;
}

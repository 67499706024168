export default {
  primary: "#070707",
  alert: {
    alert25: "#F7E0E0",
    alert50: "#EBB1B1",
    alert100: "#D86464"
  },
  warning: {
    warning25: "#FFF0CC",
    warning50: "#FFD97F",
    warning100: "#FFB400"
  },
  success: {
    success25: "#E2F6E8",
    success50: "#B5E8C4",
    success100: "#6CD28B"
  },
  white: "#ffffff",
  black: "#070707",
  background: {
    white: "#FEFEFE",
    black: "#161616",
    overlay: "rgba(7, 7, 7, 0.4)"
  },
  grey: {
    grey25: "#f6f6f6",
    grey50: "#CCCCCC",
    grey100: "#808080",
    greenish: "#E5EBE8",
    darkest: "#333335",
    greyDarker: "#585858"
  },
  chart: {
    primary: "#070707",
    secondary: "#FFB400"
  },
  effect: {
    shadow: {
      primary: "#060606",
      secondary: "#FFFFFF"
    }
  },
  keg: "#00321E"
};

import styled, { css } from "styled-components";
import TextInput, { TextInputStyles } from "./TextInput";

const SearchInput = styled(TextInput).attrs(props => ({
  prefixIcon: props.prefixIcon || "search"
}))(
  ({ theme, prefixIcon }) => css`
    border-radius: 110px;

    ${TextInputStyles.InputContainer} {
      padding-left: ${theme.spaceUnit.md};
      padding-right: ${theme.spaceUnit.xs};
    }

    &.active,
    &.focus {
      ${TextInputStyles.TextLabel} {
        padding-left: ${theme.spaceUnit.md};
      }
    }

    ${TextInputStyles.LabelContainer} {
      left: ${theme.spaceUnit.xs};
    }

    ${prefixIcon &&
    css`
      ${TextInputStyles.TextLabel} {
        padding-left: ${theme.spaceUnit.xxl};
      }
    `}

    ${TextInputStyles.Legend} {
      margin-left: ${theme.spaceUnit.sm};
    }

    ${TextInputStyles.PrefixIcon} {
      overflow: visible;
      stroke: ${theme.colors.grey.grey100};
    }
  `
);

export default SearchInput;

import { BaseButton } from "@diana-ui/button";
import styled, { css } from "styled-components";

export default styled(BaseButton).attrs(props => ({
  disabled: !!props.disabled
}))(
  ({ theme, disabled }) => css`
    border: 1px solid ${theme.colors.alert.alert100};
    box-sizing: border-box;
    border-radius: 50px;
    color: ${theme.colors.alert.alert100};
    padding: ${theme.spaceUnit.sm} ${theme.spaceUnit.xl};
    background-color: transparent;

    :hover {
      background-color: ${theme.colors.alert.alert25};
    }

    :active {
      background-color: ${theme.colors.alert.alert100};
    }

    ${disabled &&
    css`
      border-color: ${theme.colors.alert.alert50};
      color: ${theme.colors.alert.alert50};
      cursor: default;

      :hover {
        background-color: transparent;
        color: ${theme.colors.alert.alert50};
      }
    `}
  `
);
